@import '../../../Styles/settings';

.btnWrapper {
  margin-left: 24px;
}
.btn {
  padding: initial;
}

@media #{$md-up} {
  .btnWrapper {
    margin: 0;
  }
  .btn {
    padding: 4px;
    &:hover {
      background: $color-tabs-nav-border-phone;
      border-radius: 4px;
      cursor: pointer;
    }
    &:focus-visible {
      outline: 2px solid $color-tabs-nav-selected-text;
      border-radius: 2px;
    }
  }
}
