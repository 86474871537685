@import '../../../Styles/settings';

@keyframes mount {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.root {
  position: relative;
  opacity: 0;
  animation: mount 0.3s $main-timing-function 0.7s forwards;
}

.header,
.text,
.label,
.input,
.btn,
.links {
  background: white;
}

.header {
  height: 0px;
  margin-top: 30px;
}

.label {
  height: 27px;
  width: 40%;
  margin-bottom: 15px;
}

.text {
  margin-bottom: 15px;
  height: 24px;
  &.short {
    width: 60%;
  }
  &.last {
    margin-bottom: 55px;
  }
}

.input {
  height: 40px;
  margin-bottom: 30px;
}

.btn {
  margin-left: auto;
  width: 150px;
  height: 46px;
}

@media #{$md-up} {
  .root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: $color-modal-bg-transparent;
  }
  .wrapper {
    width: 100%;
    max-width: 570px;
    padding: 35px 60px;
    max-height: 90%;
    background-color: $color-modal-bg;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .header {
    height: 52px;
    width: 50%;
    margin-bottom: 30px;
  }

  .noMarginHeaderTop {
    margin-top: 0px;
  }
}
