@import '../../../Styles/settings';

.btn {
  position: relative;
  padding: 0px;
  background: transparent;
  margin: 0;
  border: none;
  text-decoration: none;
  cursor: pointer;
}

.menuBtn {
  width: 32px;
  height: 19px;
  background: transparent;
  border: none;
  padding-right: 0;
  padding-left: 12px;
  margin-left: 12px;
  cursor: pointer;
}

/* Animation of icons */
.menuIconContainer,
.closeIconContainer {
  transition: all 0.35s ease-in-out;
}

.menuBtn.active .menuIconContainer {
  transform: rotate(90deg) scale(0);
  opacity: 0;
}

.menuBtn:not(.active) .closeIconContainer {
  transform: rotate(-90deg) scale(0);
  opacity: 0;
}

.closeIconContainer,
.menuIconContainer {
  position: absolute;
  bottom: 13px;
}
