@import '../../../Styles/settings';

.root {
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  z-index: map-get($zIndexes, 'header');
}

.header {
  width: 100%;
  background: white;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
}

.scrollIdentifier {
  display: none;
}

@media #{$md-up} {
  :global(#{$rootLockedClass}) {
    .root {
      position: absolute;
    }
  }

  .header {
    transition: height 0.3s $main-timing-function;
    box-shadow: 0px 2px 6px #a8a7a914;
    &.noShadow {
      box-shadow: unset;
    }
  }
  .scrollIdentifier {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
  }

  .minimal {
    height: 80px;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    overflow: hidden;
  }

  .hover {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  }
}

@media print {
  .root {
    display: none;
  }
}
