@import '../../../../../Styles/settings';

.logoLink {
  display: inline-flex;
}

.secondaryNavigation {
  display: flex;
  align-content: center;
  align-items: center;
  vertical-align: middle;
  min-height: 64px;
  justify-content: space-between;

  .iconNavigation {
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 0;
    padding: 0;

    .iconNavigationItem {
      list-style: none;
      padding: 4px;
    }
  }
}

.mainContainer {
  align-items: center;
  height: auto;
  transition: padding-bottom 0.3s $main-timing-function;
  list-style: none;
  gap: 24px;
  display: flex;
  align-items: center;
  padding: 0;

  & > :first-child {
    margin-right: 12px;
  }
  li {
    a {
      &:focus-visible {
        outline: 2px solid $color-tabs-nav-selected-text;
        border-radius: 2px;
      }
    }
  }
}

.mainContainerExpand {
  padding-bottom: 15px;
}

.search {
  width: 28px;
}

.logo {
  width: 95px;
}
