@import '../../../../../Styles/settings';

.mainContainer {
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: $mobile-header-height;
}

.logoLink {
  display: inline-flex;
}

.bars {
  background: black;
  border-radius: 1px;
}

.logo {
  width: 85px;
}
.lang {
  margin-left: auto;
}
.arrow {
  width: 15px;
  margin-left: 10px;
}

.container {
  background: white;
}

.desktopMenuButtonSeperator {
  height: 20px;
  width: 1px;
  background: lighten($tundora, 30%);
  margin-right: 10px;
}
