@import '../../../../Styles/settings';

@keyframes svgAnimation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(4px);
  }
}

.megaMenuWrapper {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  flex-direction: column;
  align-items: center;
  background: #fff;
  box-shadow: 0px 4px 20px 0px rgba(95, 99, 104, 0.08);
  max-height: 65vh;
  overflow-y: auto;

  .menuWrapper {
    display: flex;
    gap: 32px;
    flex-direction: column;
    padding: 16px 24px 32px 24px;
    .mainLink {
      @include HelveticaMedium;
      color: $color-tabs-nav-selected-text;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
      padding: 8px;

      .arrowRight {
        margin: -3px 4px;
      }

      &:focus-visible {
        outline: 2px solid $color-tabs-nav-selected-text;
        width: max-content;
      }
      &:hover {
        .mainLinkText {
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
          .arrowRight {
            animation: svgAnimation $main-timing-function 0.4s forwards;
          }
        }
      }
    }

    .mainLinkDesc {
      @include HelveticaRegular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: $color-mega-menu-img-desc;
    }
  }

  .mainMenuDescription {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .megaMenuList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 24px;
    width: 100%;

    .megaMenuColumn {
      display: flex;
      flex-direction: column;

      .menuItemWrapper {
        display: flex;
        flex-direction: column;
        padding: 8px 0;
        font-style: normal;
        gap: 8px;
        text-decoration: none;
        padding: 8px;

        &:focus-visible {
          outline: 2px solid $color-tabs-nav-selected-text;
        }
        &:hover {
          .megaMenuColumnTitle {
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-skip-ink: none;
            text-decoration-thickness: auto;
            text-underline-offset: auto;
          }
        }
      }
      .megaMenuColumnTitle {
        @include HelveticaMedium;
        color: $color-tabs-nav-selected-text;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .menuText {
      @include HelveticaRegular;
      color: $color-mega-menu-desc;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      text-decoration: none;
      display: flex;
      flex-direction: column;
    }
    .highlightedLink {
      display: flex;
      padding: 8px;
      justify-content: flex-start;

      &:hover {
        .megaMenuColumnTitle {
          text-decoration-line: underline;
          text-decoration-style: solid;
          text-decoration-skip-ink: none;
          text-decoration-thickness: auto;
          text-underline-offset: auto;
        }
        background-color: $color-mega-menu-image-hover;
      }

      .highlightedLinkText {
        padding: 16px 8px 8px 8px;
      }
      .highlightedLinkImage {
        width: 100%;
        object-fit: cover;
        aspect-ratio: 16 / 9;
      }
    }
  }
}
